import { ReactNode, useEffect, useMemo } from 'react'
import {
  Body4,
  Body6,
  Box,
  Checkbox,
  ReactSelect,
  SelectOption,
  TextArea
} from '@sefar/design-system'
import SelectUser from '../components/select-user'
import { useOrderState } from '../hooks/use-order-state'
import {
  useCostCenters,
  useDeliveryAddresses,
  useLedgers,
  useProjects
} from '../../api'
import { User } from '../../../../api'
import { useConfigStore } from '../../../../state/config'
import { usePrevious } from 'react-use'
import Attachments from '../components/attachments'

type DetailItemProps = {
  label: string
  desc?: string
  children: ReactNode
  required?: boolean
}

const DetailItem = ({ label, desc, children, required }: DetailItemProps) => {
  return (
    <Box css={{ display: 'flex', flexDirection: 'column', mb: '$8' }}>
      <Body4 fontWeight="bold" css={{ mb: '$3' }}>
        {label}
        {required && ' *'}
      </Body4>
      <Body6 css={{ mb: '$2', color: '$neutralLighten25' }}>
        {desc}
        {required && ' *'}
      </Body6>
      {children}
    </Box>
  )
}

const mapSelectObject = (obj: { [a: string]: any }) => {
  return obj
    ? Object.keys(obj).map((key) => ({ label: obj[key], value: key }))
    : []
}

export const DetailsStep = () => {
  const { setDetails, order } = useOrderState()
  const { data: warehouseData } = useDeliveryAddresses()
  const { data: ledgers = {} } = useLedgers()
  const { data: costCenters = {} } = useCostCenters({
    ledgerAccount: order.ledgerAccount,
    project: order.project
  })
  const { data: projects = {} } = useProjects({
    ledgerAccount: order.ledgerAccount,
    costCenter: order.costCenter
  })
  const { me } = useConfigStore()
  const selectOptions = useMemo(() => {
    const warehouses = warehouseData?.data
    const warehouseMap: { [a: string]: string } = {}
    if (warehouses?.length) {
      for (const wrh of warehouses) {
        if (wrh.warehouse) {
          warehouseMap[wrh.warehouse] = wrh.warehouseDesc
        }
      }
    }
    return {
      deliveryAddresses: mapSelectObject(warehouseMap),
      costCenters: mapSelectObject(costCenters),
      ledgers: mapSelectObject(ledgers),
      projects: mapSelectObject(projects)
    }
  }, [warehouseData, costCenters, ledgers, projects])

  useEffect(() => {
    if (typeof order.urgency !== 'boolean') {
      setDetails({ ...order, urgency: false })
    }
  }, [])

  useEffect(() => {
    if (me && me.employeeid) {
      !order.requester && setDetails({ ...order, requester: me.employeeid })
    }
  }, [me?.employeeid])

  const prevLedgerAccount = usePrevious(order.ledgerAccount)
  useEffect(() => {
    if (prevLedgerAccount && order.ledgerAccount !== prevLedgerAccount) {
      setDetails({ ...order, costCenter: null, project: null })
    }
  }, [order, order.ledgerAccount, prevLedgerAccount])

  const prevProject = usePrevious(order.project)
  useEffect(() => {
    if (prevProject && order.project !== prevProject) {
      setDetails({ ...order, costCenter: null })
    }
  }, [order, order.project, prevProject])

  const prevCostCenter = usePrevious(order.costCenter)
  useEffect(() => {
    if (prevCostCenter && order.costCenter !== prevCostCenter) {
      setDetails({ ...order, project: null })
    }
  }, [order, order.costCenter, prevCostCenter])

  return (
    <Box css={{ width: '100%', maxWidth: 680 }}>
      <DetailItem label="Urgency">
        <Checkbox
          checked={order.urgency}
          onCheckedChange={(checked) => {
            if (typeof checked === 'boolean')
              setDetails({ ...order, urgency: checked })
          }}
          label={'This purchase requisition is urgent'}
        />
      </DetailItem>
      <DetailItem
        label="Requester"
        desc="Who is requesting the purchase requisition?"
        required
      >
        <SelectUser
          employeeid={order.requester}
          setUser={(user: User) => {
            setDetails({ ...order, requester: user.employeeid })
          }}
          dialogProps={{
            title: 'Assign the requester',
            description: 'Please select the requester'
          }}
        />
      </DetailItem>
      <DetailItem
        label="Delivery address"
        desc="Please select warehouse"
        required
      >
        <ReactSelect
          isSearchable
          css={{
            width: '100%',
            '& .react-select__input-container': {
              opacity: '1 !important'
            }
          }}
          value={
            order.deliveryAddress &&
            selectOptions.deliveryAddresses.find(
              (option) => option.value === order.deliveryAddress
            )
          }
          options={selectOptions.deliveryAddresses}
          onChange={(option: SelectOption) => {
            setDetails({ ...order, deliveryAddress: option.value })
          }}
          placeholder="Select warehouse"
        />
      </DetailItem>
      <DetailItem
        label="Receiver"
        desc="Who is the receiver of the purchase requisition?"
        required
      >
        <SelectUser
          employeeid={order.receiver}
          setUser={(user: User) => {
            setDetails({ ...order, receiver: user.employeeid })
          }}
          dialogProps={{
            title: 'Assign the receiver',
            description: 'Please select the receiver'
          }}
        />
      </DetailItem>
      <DetailItem
        label="Approver"
        desc="Who is the approver of the purchase requisition?"
        required
      >
        <SelectUser
          employeeid={order.approver}
          query={{ role: 'approver' }}
          setUser={(user: User) => {
            setDetails({ ...order, approver: user.employeeid })
          }}
          dialogProps={{
            title: 'Assign the approver',
            description: 'Please select the approver'
          }}
        />
      </DetailItem>
      <DetailItem label="Ledger Account" desc="Select ledger account" required>
        <ReactSelect
          isSearchable
          css={{
            width: '100%',
            '& .react-select__input-container': {
              opacity: '1 !important'
            }
          }}
          value={
            order.ledgerAccount && ledgers[order.ledgerAccount]
              ? {
                  label: ledgers[order.ledgerAccount],
                  value: order.ledgerAccount
                }
              : null
          }
          options={selectOptions.ledgers}
          onChange={(option: SelectOption) => {
            setDetails({ ...order, ledgerAccount: option.value })
          }}
          placeholder="Select ledger account"
        />
      </DetailItem>
      <DetailItem label="Cost Center" desc="Select cost center" required>
        <ReactSelect
          isDisabled={
            !order.ledgerAccount || !selectOptions?.costCenters?.length
          }
          isSearchable
          isClearable
          css={{
            width: '100%',
            '& .react-select__input-container': {
              opacity: '1 !important'
            }
          }}
          value={
            order.costCenter && costCenters[order.costCenter]
              ? {
                  label: costCenters[order.costCenter],
                  value: order.costCenter
                }
              : null
          }
          options={selectOptions.costCenters}
          onChange={(option: SelectOption) => {
            if (option === null) setDetails({ ...order, costCenter: undefined })
            setDetails({ ...order, costCenter: option.value })
          }}
          placeholder="Select warehouse"
        />
      </DetailItem>
      <DetailItem label="Project" desc="Select project" required>
        <ReactSelect
          isDisabled={!order.ledgerAccount || !selectOptions?.projects?.length}
          value={
            order.project && projects[order.project]
              ? {
                  label: projects[order.project],
                  value: order.project
                }
              : null
          }
          isSearchable
          isClearable
          css={{
            width: '100%',
            '& .react-select__input-container': {
              opacity: '1 !important'
            }
          }}
          options={selectOptions.projects}
          onChange={(option: SelectOption) => {
            if (option === null) setDetails({ ...order, project: undefined })
            setDetails({ ...order, project: option.value })
          }}
          placeholder="Select project"
        />
      </DetailItem>
      <DetailItem
        label="Buyer internal contact"
        desc="Select buyer internal contact"
      >
        <SelectUser
          employeeid={order.buyerInternalContact}
          setUser={(user: User) => {
            setDetails({ ...order, buyerInternalContact: user.employeeid })
          }}
          dialogProps={{
            title: 'Assign the internal contact',
            description: 'Please select the internal contact'
          }}
        />
      </DetailItem>
      <DetailItem
        label="Additional info"
        desc="Provide any needed additional information"
      >
        <TextArea
          css={{ width: '100%', minHeight: '80px' }}
          value={order.additionalInfo}
          onChange={(e) => {
            setDetails({ ...order, additionalInfo: e.target.value })
          }}
        />
      </DetailItem>
      <DetailItem label="Attachment">
        <Attachments
          attachments={order.attachments}
          onAttachmentsUpload={(attachments) => {
            setDetails({ ...order, attachments })
          }}
        />
      </DetailItem>
    </Box>
  )
}
