import create, { StateCreator } from 'zustand'
import { PersistOptions } from 'zustand/middleware'
import {
  PurchaseRequisitionOrder,
  PurchaseRequisitionOrderItem
} from '../types'

// const PERSIST_STORAGE_KEY = 'sefar-purchase-requisition-order-storage'
import { object, string, array, boolean } from 'yup'

export const detailsSchema = object({
  urgency: boolean()?.required().label('Urgency'),
  requester: string().required().label('Requester'),
  deliveryAddress: string().required().label('Delivery Address'),
  receiver: string().required().label('Receiver'),
  approver: string().required().label('Approver'),
  costCenter: string().nullable().label('Cost Center'),
  project: string().nullable().optional().label('Project'),
  ledgerAccount: string().required().label('Ledger Account'),
  buyerInternalContact: string().nullable().label('Buyer internal contact'),
  additionalInfo: string().nullable().optional().label('Additional Info'),
  attachments: array().nullable().optional().of(string()).label('Attachments')
}).test(
  'general_leger',
  'Either Project or Cost Center required',
  function (values) {
    const { project, costCenter } = values
    if (!project && !costCenter) {
      return this.createError({
        path: 'costCenter',
        message: 'Either Project or Cost Center required'
      })
    }

    return true
  }
)

type PurchaseRequisitionOrderStateData = {
  order: PurchaseRequisitionOrder
  errors: { [a: string]: string } | null
}

type PurchaseRequisitionOrderStateSetters = {
  setItems: (items: PurchaseRequisitionOrderItem[]) => void
  setDetails: (details: Partial<PurchaseRequisitionOrder>) => void
  setErrors: (errors: { [a: string]: string } | null) => void
  reset: () => void
}

export type PurchaseRequisitionOrderState = PurchaseRequisitionOrderStateData &
  PurchaseRequisitionOrderStateSetters

type PurchaseRequisitionOrderStatePersist = (
  config: StateCreator<PurchaseRequisitionOrderState>,
  options: PersistOptions<PurchaseRequisitionOrderState>
) => StateCreator<PurchaseRequisitionOrderState>

const initialState: PurchaseRequisitionOrderStateData = {
  order: {
    urgency: false,
    purchaseRequisitionItems: []
  },
  errors: null
}

export const useOrderState = create<PurchaseRequisitionOrderState>(
  // (persist as PurchaseRequisitionOrderStatePersist)(
  (set) => ({
    ...initialState,
    setItems: (purchaseRequisitionItems: PurchaseRequisitionOrderItem[]) =>
      set((state) => ({ order: { ...state.order, purchaseRequisitionItems } })),
    setDetails: (details: Partial<PurchaseRequisitionOrder>) =>
      set((state) => ({
        order: {
          purchaseRequisitionItems: state.order.purchaseRequisitionItems,
          ...details
        }
      })),
    setErrors: (errors: { [a: string]: string } | null) => {
      set((state) => {
        return { ...state, errors }
      })
    },
    reset: () => set(initialState)
  })
)
